<template>
</template>

<script>
import { getCurrentInstance, onBeforeMount } from "@vue/composition-api";
import {initialAbility} from "@/plugins/acl/config";
import {useRouter} from "@core/utils";
import store from '@/store';
import axios from "@axios";

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const {router} = useRouter()
    onBeforeMount(() => {
      axios.post('auth/logout').then(res => {
        localStorage.removeItem('accessToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')
        store.commit('nav/SET_IS_AMAZON_ALERT', true)
        store.commit('nav/SET_IS_PAYOUT_ALERT', false)

        // Clear products filter
        store.commit("filter/CLEAR_SAVED_FILTER_DATA")

        // Reset ability
        vm.$ability.update(initialAbility)

        // Redirect to login page
        router.push({name: 'auth-login'})
      }).catch(err => {
        localStorage.removeItem('accessToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')
        store.commit('nav/SET_IS_AMAZON_ALERT', true)
        store.commit('nav/SET_IS_PAYOUT_ALERT', false)

        // Clear products filter
        store.commit("filter/CLEAR_SAVED_FILTER_DATA")

        // Reset ability
        vm.$ability.update(initialAbility)

        // Redirect to login page
        router.push({name: 'auth-login'})
      })
    })
  },
}
</script>
